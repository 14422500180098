import ListModels from '../../components/listModels/listModels';

function Models() {

    return (
        <div className='pcUser-container'>
            <div className='pcUser-bloco'>
                <header className='max-header'>
                    <h1>Lista de Modelos</h1>
                </header>
                <ListModels />
            </div>
        </div>
    )
}

export default Models;