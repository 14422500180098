// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.normal {
    flex: 2 1;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: rgb(245, 153, 153);
}

.normalnormal {
    box-shadow: none;
}

.title {
margin-bottom: 20px;
}

.chartGrid {
stroke: rgb(228, 225, 225);
}

.loading-div {
    align-items: center;
}

.loading-icon {
    margin-top: 175px;
    margin-left: 50%;
    color: #078E9C;
}
`, "",{"version":3,"sources":["webpack://./src/components/charts/barPlot/barPlot.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IAEP,sDAAsD;IACtD,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;AACA,mBAAmB;AACnB;;AAEA;AACA,0BAA0B;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".normal {\n    flex: 2;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n    padding: 10px;\n    color: rgb(245, 153, 153);\n}\n\n.normalnormal {\n    box-shadow: none;\n}\n\n.title {\nmargin-bottom: 20px;\n}\n\n.chartGrid {\nstroke: rgb(228, 225, 225);\n}\n\n.loading-div {\n    align-items: center;\n}\n\n.loading-icon {\n    margin-top: 175px;\n    margin-left: 50%;\n    color: #078E9C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
