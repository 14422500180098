// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-attentionModalHeader img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.max-attentionModalHeader h5 {
  color: white;
  margin: 0;
  font-weight: bold;
}

.max-attentionModalHeader {
  background-color: #078E9C;
}
`, "",{"version":3,"sources":["webpack://./src/components/attentionMessage/AttentionMessage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".max-attentionModalHeader img {\n  width: 2rem;\n  height: 2rem;\n  margin-right: 0.5rem;\n}\n\n.max-attentionModalHeader h5 {\n  color: white;\n  margin: 0;\n  font-weight: bold;\n}\n\n.max-attentionModalHeader {\n  background-color: #078E9C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
