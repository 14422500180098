// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
    flex: 2 1;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    width: 95%;
}

.title {
    margin-bottom: 20px;
}

.loading-div {
    align-items: center;
}

.loading-icon {
    margin-top: 90px;
    margin-bottom: 90px;
    margin-left: 50%;
    color: #078E9C;
}
`, "",{"version":3,"sources":["webpack://./src/components/map/map.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IAEP,sDAAsD;IACtD,aAAa;IACb,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".map {\n    flex: 2;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n    padding: 10px;\n    width: 95%;\n}\n\n.title {\n    margin-bottom: 20px;\n}\n\n.loading-div {\n    align-items: center;\n}\n\n.loading-icon {\n    margin-top: 90px;\n    margin-bottom: 90px;\n    margin-left: 50%;\n    color: #078E9C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
