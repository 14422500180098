// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close{
    margin-top: 25px;
    margin-left: 48.5%;

}

.spinner-div{
    display: flex;
    justify-content: center;
    height: 50px;
}

.footer{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.body{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.boxUploud{
    margin-top: 20px;
    margin-bottom: 20px;
    border: solid 2px rgb(191, 191, 191);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border-style: dashed;
    padding: 20px;
}

.boxUploud form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 10px;
}

.btn-save{
    background-color: #078E9C;
    color: white;
}

.btn-save:hover{
    background-color: #ffffff;
    color: #078E9C;
    border: 2px solid #078E9C;
}

#ModalImportPDFs {
    color: #055E67;
    font-weight: bold;
}

#ModalImportPDFs h1{
    color: #055E67;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;;AAEtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,oCAAoC;IACpC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".close{\n    margin-top: 25px;\n    margin-left: 48.5%;\n\n}\n\n.spinner-div{\n    display: flex;\n    justify-content: center;\n    height: 50px;\n}\n\n.footer{\n    display: flex;\n    justify-content: center;\n    padding-bottom: 10px;\n}\n\n.body{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-bottom: 10px;\n}\n\n.boxUploud{\n    margin-top: 20px;\n    margin-bottom: 20px;\n    border: solid 2px rgb(191, 191, 191);\n    border-radius: 5px;\n    width: 100%;\n    height: 100%;\n    border-style: dashed;\n    padding: 20px;\n}\n\n.boxUploud form {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    height: 100%;\n    gap: 10px;\n}\n\n.btn-save{\n    background-color: #078E9C;\n    color: white;\n}\n\n.btn-save:hover{\n    background-color: #ffffff;\n    color: #078E9C;\n    border: 2px solid #078E9C;\n}\n\n#ModalImportPDFs {\n    color: #055E67;\n    font-weight: bold;\n}\n\n#ModalImportPDFs h1{\n    color: #055E67;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
