// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-inputSelect {
    height: 2.5rem;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/PainelDeControle/Threshold/Threshold.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,SAAS;AACb","sourcesContent":[".max-inputSelect {\n    height: 2.5rem;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
