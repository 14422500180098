import React from "react";

function Cadastrar(){
    return(
        <div className='home'>
            <h1>Home</h1>
        </div>
    )
}

export default Cadastrar;