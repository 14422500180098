// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltipMax {
    position: absolute;
    display: inline-block;
    padding: 10px;
    margin-left: 20px;
    color: white;
    transition: opacity 2.3s;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #F5F5F5;
    width: 200px;
    border: #055E67 solid 1px;
    z-index: 10;
}

.tooltip-text {
    color: #055E67;
    font-size: 14px;
    margin: 0;
}

.toptop {
    align-self: self-start;
}`, "",{"version":3,"sources":["webpack://./src/components/tooltip/tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,wBAAwB;IACxB,+CAA+C;IAC/C,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;IACf,SAAS;AACb;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".tooltipMax {\n    position: absolute;\n    display: inline-block;\n    padding: 10px;\n    margin-left: 20px;\n    color: white;\n    transition: opacity 2.3s;\n    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n    background: #F5F5F5;\n    width: 200px;\n    border: #055E67 solid 1px;\n    z-index: 10;\n}\n\n.tooltip-text {\n    color: #055E67;\n    font-size: 14px;\n    margin: 0;\n}\n\n.toptop {\n    align-self: self-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
