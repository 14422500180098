// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configespecial {
    width: 30px;
}

.max-table {
    color: #055E67;
}

.max-iconEdit {
    width: 1.4375rem;
    height: 1.4375rem;
}

.configespecial button {
    background: transparent;
}

.configespecial button:hover {
    opacity: 0.5;
}

#max-firstTable {
    width: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/listUser/listUsers.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".configespecial {\n    width: 30px;\n}\n\n.max-table {\n    color: #055E67;\n}\n\n.max-iconEdit {\n    width: 1.4375rem;\n    height: 1.4375rem;\n}\n\n.configespecial button {\n    background: transparent;\n}\n\n.configespecial button:hover {\n    opacity: 0.5;\n}\n\n#max-firstTable {\n    width: 3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
