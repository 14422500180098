// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget{
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    padding: 10px;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: 100px;
}

.left{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upperLeft {
  font-weight: bold;
  font-size: 14px;
  color: rgb(160, 160, 160);
}

.centralLeft {
  font-size: 28px;
  font-weight: 300;
}

.bottonLeft {
  width: max-content;
  font-size: 12px;
  border-bottom: 1px solid gray;
}

.upperRight {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.iconn {
  font-size: 18px;
  padding: 2px;
  border-radius: 10px;
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/widget/widget.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAO;IACP,aAAa;IAEb,sDAAsD;IACtD,mBAAmB;IACnB,aAAa;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".widget{\n    display: flex;\n    justify-content: space-between;\n    flex: 1;\n    padding: 10px;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n    border-radius: 10px;\n    height: 100px;\n}\n\n.left{\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.right{\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.upperLeft {\n  font-weight: bold;\n  font-size: 14px;\n  color: rgb(160, 160, 160);\n}\n\n.centralLeft {\n  font-size: 28px;\n  font-weight: 300;\n}\n\n.bottonLeft {\n  width: max-content;\n  font-size: 12px;\n  border-bottom: 1px solid gray;\n}\n\n.upperRight {\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n}\n\n.iconn {\n  font-size: 18px;\n  padding: 2px;\n  border-radius: 10px;\n  align-self: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
