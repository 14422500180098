// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .featured {
    flex: 2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px; 
} */

.top {
display: flex;
align-items: center;
justify-content: space-between;
color: gray;
}

.title {
font-size: 16px;
font-weight: 500;
}

/* .bottom {
padding: 40px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 15px;
} */

.featuredChart {
    width: 100px;
    height: 100px;
}

.title {
    font-weight: 500;
    color: gray;
}

.amount {
    font-size: 30px;
}

.desc {
    font-weight: 300;
    font-size: 12px;
    color: gray;
    text-align: center;
}

.summary {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.positive {
    color: green;
    }

.negative {
    color: red;
    }

.item {
text-align: center;
}

.itemTitle {
    font-size: 14px;
    color: gray;
}

.itemResult {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
}

.alert-icon{
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/featuredChart/featuredChart.css"],"names":[],"mappings":"AAAA;;;;;GAKG;;AAEH;AACA,aAAa;AACb,mBAAmB;AACnB,8BAA8B;AAC9B,WAAW;AACX;;AAEA;AACA,eAAe;AACf,gBAAgB;AAChB;;AAEA;;;;;;;GAOG;;AAEH;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,YAAY;IACZ;;AAEJ;IACI,UAAU;IACV;;AAEJ;AACA,kBAAkB;AAClB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":["/* .featured {\n    flex: 2;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n    padding: 10px; \n} */\n\n.top {\ndisplay: flex;\nalign-items: center;\njustify-content: space-between;\ncolor: gray;\n}\n\n.title {\nfont-size: 16px;\nfont-weight: 500;\n}\n\n/* .bottom {\npadding: 40px;\ndisplay: flex;\nflex-direction: column;\nalign-items: center;\njustify-content: center;\ngap: 15px;\n} */\n\n.featuredChart {\n    width: 100px;\n    height: 100px;\n}\n\n.title {\n    font-weight: 500;\n    color: gray;\n}\n\n.amount {\n    font-size: 30px;\n}\n\n.desc {\n    font-weight: 300;\n    font-size: 12px;\n    color: gray;\n    text-align: center;\n}\n\n.summary {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n.positive {\n    color: green;\n    }\n\n.negative {\n    color: red;\n    }\n\n.item {\ntext-align: center;\n}\n\n.itemTitle {\n    font-size: 14px;\n    color: gray;\n}\n\n.itemResult {\n    display: flex;\n    align-items: center;\n    margin-top: 10px;\n    font-size: 14px;\n}\n\n.alert-icon{\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
