// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-alertmessage{
  position: fixed;
  top: -180px; /* Comece fora da tela no topo */
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out; /* Adicione uma transição suave */
  z-index: 9999;
}

.max-alertmessage.show {
  top: 90px; /* Desça para mostrar */
}`, "",{"version":3,"sources":["webpack://./src/components/message/Message.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW,EAAE,gCAAgC;EAC7C,SAAS;EACT,2BAA2B;EAC3B,gCAAgC,EAAE,iCAAiC;EACnE,aAAa;AACf;;AAEA;EACE,SAAS,EAAE,uBAAuB;AACpC","sourcesContent":[".max-alertmessage{\n  position: fixed;\n  top: -180px; /* Comece fora da tela no topo */\n  left: 50%;\n  transform: translateX(-50%);\n  transition: top 0.3s ease-in-out; /* Adicione uma transição suave */\n  z-index: 9999;\n}\n\n.max-alertmessage.show {\n  top: 90px; /* Desça para mostrar */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
