// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exit-direction-button{
    background-color: #078E9C;
    color: white;
}

.exit-direction-button:active{
    color: #078E9C;
    border: 2px solid #078E9C;
}`, "",{"version":3,"sources":["webpack://./src/components/Toggle/toggle.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".exit-direction-button{\n    background-color: #078E9C;\n    color: white;\n}\n\n.exit-direction-button:active{\n    color: #078E9C;\n    border: 2px solid #078E9C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
