// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.normal {
    flex: 4 1;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: rgb(245, 153, 153);
}

.title {
margin-bottom: 20px;
font-size: 2rem !important;
}

.cheioMax {
    width: 100%;
}

.chartGrid {
stroke: rgb(228, 225, 225);
}

.loading-div {
    align-items: center;
}

.loading-icon {
    margin-top: 175px;
    margin-left: 50%;
    color: #078E9C;
}

.buttons{
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    padding-right: 2%;
    padding-top: 2px;
    padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/charts/curveChart/curveChart.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IAEP,sDAAsD;IACtD,aAAa;IACb,yBAAyB;AAC7B;;AAEA;AACA,mBAAmB;AACnB,0BAA0B;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;AACA,0BAA0B;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".normal {\n    flex: 4;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n    padding: 10px;\n    color: rgb(245, 153, 153);\n}\n\n.title {\nmargin-bottom: 20px;\nfont-size: 2rem !important;\n}\n\n.cheioMax {\n    width: 100%;\n}\n\n.chartGrid {\nstroke: rgb(228, 225, 225);\n}\n\n.loading-div {\n    align-items: center;\n}\n\n.loading-icon {\n    margin-top: 175px;\n    margin-left: 50%;\n    color: #078E9C;\n}\n\n.buttons{\n    display: flex;\n    gap: 20px;\n    justify-content: flex-end;\n    padding-right: 2%;\n    padding-top: 2px;\n    padding-bottom: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
