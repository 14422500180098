// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnn{
    background-color: #078E9C;
    color: white;
    height: 5vh;
    width: 5em;
    border: none;
    border-radius: 5px;
}
.btnn:hover{
    background-color: white;
    color: #078E9C;
    border-radius: 5px;
}

.svg{
    color: black;
}

.nvEstrutura-container{
    margin: 80px 0 0 54px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PainelDeControle/NovaEstrutura/NovaEstrutura.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,uBAAuB;IACvB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".btnn{\n    background-color: #078E9C;\n    color: white;\n    height: 5vh;\n    width: 5em;\n    border: none;\n    border-radius: 5px;\n}\n.btnn:hover{\n    background-color: white;\n    color: #078E9C;\n    border-radius: 5px;\n}\n\n.svg{\n    color: black;\n}\n\n.nvEstrutura-container{\n    margin: 80px 0 0 54px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
